import IndexPages__GenericIndexTableController from "../generic_index_table_controller"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
import { initSelect2 } from 'shared/select2_init'; 
import { flashMessage } from 'shared/notify';

// Connects to data-controller="index-pages--admin--commercial-action-index-table"
export default class extends IndexPages__GenericIndexTableController {
  checkActionButton() {
    if(this.selectedRows.length > 0) {
      $('#commercial_actions_batch_actions_container').removeClass('d-none')
      if($('#commercial_actions_batch_actions_container .select2').length == 0) {
        initSelect2($('#commercial_actions_batch_actions_container select'))
      }
      let count
      if($('.index-table__checkbox--all').prop('checked')) {
        count = $(this.element).data().count
      } else {
        count = this.selectedRows.length
      }
      $('#commercial_actions_batch_actions_container .btn').text(`Appliquer sur ${count} actions commerciales`)
    } else {
      $('#commercial_actions_batch_actions_container').addClass('d-none')
    }
  }

  toggleCheckbox(e) {
    if(!$(e.currentTarget).prop('checked')) {
      $('.index-table__checkbox--all').prop('checked', false)
    }
    this.checkActionButton()
  }

  accomplish_action(e) {
    ajaxTurboStream(`/admin/commercial_actions/${$(e.currentTarget).data().id}/accomplished_action`)
  }

  applyBatchAction() {
    let query_params = {}
    if($('.index-table__checkbox--all').prop('checked')) {
      query_params.filters = $(`#${$(this.element).data().mainController}`).get(0).controller.getParams()
    } else {
      query_params.filters = {id: this.selectedRows.map((i, e) => $(e).data().id).get()}
    }

    switch($('#commercial_actions_batch_actions_container select').val()) {
      case 'update_date':
        ajaxPostTurboStream(
          '/admin/commercial_actions/prepare_update_batch_dates',
          query_params
        )
        break;
      case 'transfer_prospects':
        ajaxPostTurboStream(
          '/admin/commercial_actions/prepare_batch_transfer_prospects',
          query_params
        )
        break;
      case 'cancel_batch_commercial_actions':
        ajaxPostTurboStream(
          '/admin/commercial_actions/cancel_batch_commercial_actions',
          query_params.filters,
          () => {
            $('#index-page--commercial_actions').get(0).controller.filter()
            flashMessage('', `Les modifications ont été appliquées`, 'success')
          }
        )
      default:
    }
  }
}
