import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
import { flashMessage } from 'shared/notify';

// Connects to data-controller="admin--prospects--prospects-batch-transfer"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).validate({
      rules: {
        'prospect[toporder_employee_id]': {required: true}
      }
    })
  }

  submitForm() {
    if(!$(this.element).valid()) { return }
    let body = $(this.element).data().filters
    body.change_toporder_employee_id = $(this.element).find('#prospect_toporder_employee_id').val()
    ajaxPostTurboStream(
      '/admin/prospects/batch_transfer',
      body,
      () => {
        $('#modal .modal').modal('hide')
        $('#index-page--prospects').get(0).controller.filter()
        flashMessage('', `Les modifications ont été appliquées`, 'success')
      }
    )
  }
}
