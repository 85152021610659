import { Controller } from "@hotwired/stimulus"
import { TurboToporderTable } from 'shared/turbo_toporder_table';
// Connects to data-controller="index-pages--generic-index-table"
export default class extends Controller {
  // static targets = ["checkbox"];
  // static values = { url: String };
  connect() {
    this.element['controller'] = this
    if($(this.element).find('table').length > 0) {
      new TurboToporderTable(
        $(this.element).find('table'),
        undefined,
        undefined,
        $(`#${$(this.element).data().mainController}`).get(0).controller
      )      
      $('.menu_export_xlsx').show()
      $('.menu_export_xlsx').addClass('btn')
    } else {
      $('.menu_export_xlsx').hide()
    }
    this.customConnect()
  }

  customConnect() {}

  toggleAll(e) {
    $(this.element).find('td.turbo_cell .custom-control-input').prop('checked', $(e.currentTarget).prop('checked'))
    this.checkActionButton()
  }

  get selectedRows() {
    return $(this.element).find('tbody tr').filter((i, e) => $(e).find('.custom-control-input').prop('checked'))
  }

  checkActionButton() {}


  debounceClick(event) {
    event.preventDefault(); // Prevent the default link action

    let link = event.target.closest('a');
    window.open(link.href, "_blank");

    const links = link.parentElement.querySelectorAll('a');
    links.forEach(function(a) {
      a.style.pointerEvents = "none"; // Disable clicking
      a.style.opacity = "0.5"; // Optionally change appearance
    });

    setTimeout(() => {
      links.forEach(function(a) {
        a.style.pointerEvents = "auto"; // Disable clicking
        a.style.opacity = "1"; // Optionally change appearance
      })
    }, 10000); // 10 seconds
  }

  /**

  toggleAll = () => {
    const isChecked = this.selectAllCheckbox.checked;
    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
    this.updateActions()
  };

  updateActions = () => {
    // all checked rows
    const checkedRows = Array.from(this.checkboxes)
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.closest('tr'))

    if (checkedRows.length === 0) {
      this.actionButtons.forEach(button => (button.disabled = true))
      return;
    }

    const actionsPerRow = checkedRows.map(row => {
      const actionsData = row.dataset.actions;
      return actionsData ? actionsData.split(' ') : [];
    });

    // verify for each actions button if all row permit `data-actions`
    this.actionButtons.forEach(button => {
      const action = button.dataset.actions;

      const isEnabled = actionsPerRow.every(actions => {
        // use wildcard for all actions permitted ['*']
        const isStarAction = actions.length === 1 && actions[0] === '*';
        return isStarAction || actions.includes(action);
      });
      button.disabled = !isEnabled;
    });
  };

  getSelectedIds() {
    return this.checkboxTargets
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value);
  }

  **/
}
