import IndexPages__GenericIndexTableController from "../generic_index_table_controller"
import { ajaxTurboStream, ajaxPostTurboStream, ajaxPatchTurboStream } from 'shared/ajax_turbo_stream';
import { initSelect2 } from 'shared/select2_init';

// Connects to data-controller="index-pages--admin--prospect-index-table"
export default class extends IndexPages__GenericIndexTableController {
  editTemperature(e){
    initModal()
    $('.modal .modal-dialog .modal-body').html(this.populateTemperatures($(e.currentTarget).data().temperature))
    $('.modal-title').text("Modification de la température")
    $('#modal_rails_5').modal('show')
    $('.modal-dialog').addClass('modal-l')
    $('.modal-footer').hide()
    this.temperature_element = $(e.currentTarget)
  }

  customConnect() {
    this.colors = {
      'no_information': {style: 'bg-transparent' ,name: "Pas d’information (+10%)"},
      'will_sign': {style: 'bg-red', name: 'Information qui signe chez nous (+90%)'},
      'interested_by_product': {style: 'bg-orange', name: 'Très intéressé par le produit (+60%)'},
      'undecided': {style: 'bg-yellow', name: 'Incertain, en recherche active (+30%)'},
      'not_interested': {style: 'bg-lightblue', name: 'Pas intéressé dû au produit TopOrder (+2%)'},
      //'lost': {style: 'bg-lost', name: 'Perdu (0%)'}
    }

    $(document).on('click', '.prospect_square_temperature', (p) => {
      let new_temperature = $(p.currentTarget).data().temperature

      let field_temperature = this.temperature_element.parent()

      $.ajax({
        method: 'PUT',
        data: {temperature : new_temperature},
        url: '/admin/prospects/'+this.temperature_element.data().id+'/update_temperature'
      }).done(() => {
        field_temperature.removeClass('bg-red bg-orange bg-yellow bg-lightblue bg-lost bg-transparent')
        field_temperature.addClass(this.colors[new_temperature.toString()].style)
        this.temperature_element.attr('data-temperature', new_temperature);
        $('.modal').modal('hide')
      })
    })
  }

  checkActionButton() {
    if(this.selectedRows.length > 0) {
      $('#prospects_batch_actions_container').removeClass('d-none')
      if($('#prospects_batch_actions_container .select2').length == 0) {
        initSelect2($('#prospects_batch_actions_container select'))
      }
      let count
      if($('.index-table__checkbox--all').prop('checked')) {
        count = $(this.element).data().count
      } else {
        count = this.selectedRows.length
      }
      $('#prospects_batch_actions_container .btn').text(`Appliquer sur ${count} prospects`)
    } else {
      $('#prospects_batch_actions_container').addClass('d-none')
    }
  }

  toggleCheckbox(e) {
    if(!$(e.currentTarget).prop('checked')) {
      $('.index-table__checkbox--all').prop('checked', false)
    }
    this.checkActionButton()
  }



  newCommercialAction(e) {
    ajaxTurboStream(`/admin/commercial_actions/new?prospect_id=${parseInt($(e.currentTarget).parents('tr').eq(0).data().id)}`)
  }

  populateTemperatures(temperature){
    let html = `<div class=" align-items" style="justify-content: space-around;" id="prospect_background">`
    $.each (this.colors, function(key, val){
      html += `
        <div class="prospect_square_temperature cursor-pointer" data-temperature="${key}">
          <div class="${val.style}"></div>
          <p>${val.name}</p>
        </div>
      `
    })
    html += `</div>`
    return html
  }

  callProspect(e) {
    ajaxTurboStream(`/admin/commercial_actions/new?prospect_id=${parseInt($(e.currentTarget).parents('tr').eq(0).data().id)}&outgoing_phone_call=true`)
  }

  applyBatchAction() {
    let query_params = {}
    if($('.index-table__checkbox--all').prop('checked')) {
      query_params.filters = $(`#${$(this.element).data().mainController}`).get(0).controller.getParams()
      if($(this.element).data().suspect) {
        query_params.filters.only_suspect = 'true'
      }
    } else {
      query_params.filters = {id: this.selectedRows.map((i, e) => $(e).data().id).get()}
    }

    switch($('#prospects_batch_actions_container select').val()) {
      case 'archive':
        ajaxPatchTurboStream(
          '/admin/prospects/archive_batch',
          query_params.filters,
          () => {
            $('#index-page--prospects').get(0).controller.filter()
            flashMessage('', `Les modifications ont été appliquées`, 'success')
          }
        )
        break;
      case 'destroy':
        ajaxPatchTurboStream(
          '/admin/prospects/destroy_batch',
          query_params.filters,
          () => {
            $('#index-page--prospects').get(0).controller.filter()
            flashMessage('', `Les modifications ont été appliquées`, 'success')
          }
        )
        break;
      case 'transfer':
        ajaxPostTurboStream(
          '/admin/prospects/new_batch_transfer',
          query_params.filters,
        )
        break;
      case 'commercial_action':
        ajaxPostTurboStream(
          '/admin/prospects/new_commercial_actions_batch',
          query_params.filters,
        )
        break;
      default:
    }
  }
}
