import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--dashboard-final-decision-card"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    $(this.element).find('canvas').addClass('stats_container')
    renderDatas()
    $(this.element).find('select').on('select2:select', () => {
      reloadDatas()
    })
  }

  statsData() {
    let params = {}
    $.each($(this.element).find('select'), (i, select) => {
      if($(select).val() != '_') {
        params[$(select).data().key] = $(select).val()
      }
    })
    return params
  }
}
