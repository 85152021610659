import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
import { ExportXlsx } from 'shared/export_xlsx';
import { flashMessage } from 'shared/notify'

// Connects to data-controller="admin--dashboard--prospect-master-card"
export default class extends Controller {
  connect() {
    this.reload()
    $(this.element).find('select').on('select2:select', () => {this.reload()})
  }

  reload() {
    $(this.element).addClass('loading-card')
    let params = {
      year: $('#admin-dashboard__prospect-master__year-select').val(),
      range: $('#admin-dashboard__prospect-master__range-select').val()
    }
    if($('#admin-dashboard__prospect-master__prospect-inbound-type-select').val() != '_') {
      params.prospectInboundTypeId = $('#admin-dashboard__prospect-master__prospect-inbound-type-select').val()
    }
    if($('#admin-dashboard__prospect-master__prospect-origin-select').val() != '_') {
      params.prospectOriginId = $('#admin-dashboard__prospect-master__prospect-origin-select').val()
    }
    ajaxPostTurboStream(
      '/admin/dashboard/load_prospects_master_card',
      params,
      () => {$(this.element).removeClass('loading-card')},
      false
    )
  }

  export() {
    new ExportXlsx('admin-dashboard__prospect-master-card__table', {}, 'export-kpis').render()
  }

  exportMake() {
    $.get('/admin/dashboard/export_prospects_master_card_make').done((data) => {
      flashMessage('', `Appel transmis à Make. Réponse : ${data.code}`, 'success')
    })
  }
}
