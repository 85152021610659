import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="admin--z-ticket-regenerator-form"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
  }

  submitForm() {
    if($('#z_ticket_day_id').val().length == 0 && $('#z_ticket_date').val().length == 0) {
      $('#z-ticket-regenerator__error-message').removeClass('d-none')
    } else {
      $('#z-ticket-regenerator__error-message').addClass('d-none')
      ajaxPostTurboStream(
        '/admin/z_ticket_regenerator/send_push',
        {
          shop_id: $('#z_ticket_shop_id').val(),
          z_ticket_type: $('.z_ticket_z_ticket_type .custom-checkbox :checked').val(),
          date: $('#z_ticket_date').val(),
          day_id: $('#z_ticket_day_id').val()
        }
      )
    }
  }
}
