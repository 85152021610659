import IndexPages__GenericIndexPageController from "../generic_index_page_controller"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="index-pages--admin--prospect-index-page"
export default class extends IndexPages__GenericIndexPageController {
  customConnect() {
    this.checkStatuses()
    $(this.element).find('#index-page-filters__input__status').on("change", (e) => {
      this.checkStatuses()
    })

    this.initSearch()
  }

  initSearch() {
    $( "#prospect_index_search" ).autocomplete({
      minLength: 3,
      delay: 300,
      source: ( request, response ) => {
        if(request.term.trim().length == 0) { return false; }
        let term = new URLSearchParams({term: request.term.toLowerCase(), suspect: window.location.href.includes('suspect')}).toString()
        $.get(`/admin/prospects/live_autocomplete?${term}`).done(data => {
          response(data.prospects.filter((prospect, index) => {
            return true
          }).slice(0, 15))
        })
      },
      select: function( event, ui ) {
        Turbo.visit(`/admin/prospects/${ui.item.id}/edit`)
        return false;
      }
    })
  }

  checkStatuses() {
    $(this.element).find('#index-page-filters__input__prospect_commercial_step_id').parent().toggle(
      !$('#index-page-filters__input__status').val().includes('signed')
    )
  }
}
