import IndexPages__GenericIndexTableController from "../generic_index_table_controller"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="index-pages--admin--device-index-table"
export default class extends IndexPages__GenericIndexTableController {
  checkboxToggled() {
    this.checkActionButton()
    if(this.selectedRows.length == $(this.element).find('.turbo_row.turbo_cell').length) {
      $(this.element).find('.index-table--shop_devices__checkbox--all').prop('checked', true)
    } else if(this.selectedRows.length == 0) {
      $(this.element).find('.index-table--shop_devices__checkbox--all').prop('checked', false)
    }
  }

  toggleAll(e) {
    $(this.element).find('td.turbo_cell .custom-control-input').prop('checked', $(e.currentTarget).prop('checked'))
    this.checkActionButton()
  }

  get selectedRows() {
    return $(this.element).find('.turbo_row.turbo_cell').filter((i, e) => $(e).find('.custom-control-input').prop('checked'))
  }

  checkActionButton() {
    $(this.element).find('.index-table--shop_devices__action').toggleClass(
      'd-none',
      $(this.element).find('td.turbo_cell .custom-control-input:checked').length == 0
    )
  }

  callAction() {
    let deviceShops = this.selectedRows.map((i, e) => `${$(e).data().deviceId}/${$(e).data().shopId}`).get()
    this.newPush(deviceShops, $(this.selectedRows.eq(0)).data().type)
  }

  clickTurboCell(e) {
    this.newPush([`${e.data().deviceId}/${e.data().shopId}`], e.data().type)
  }

  newPush(deviceShops, deviceType) {
    let controller_name = ['toporder_connector', 'toporder_connector_android'][parseInt(deviceType)]
    ajaxPostTurboStream(
      `/admin/${controller_name}/new_multiple_push`,
      {deviceShops: deviceShops}
    )
  }
}
