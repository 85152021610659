import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
import { flashMessage } from 'shared/notify';

// Connects to data-controller="admin--commercial-actions"
export default class extends Controller {
  connect() {
    this.element[this.identifier] = this

  }

  add_action(e) {
    ajaxTurboStream(`/admin/commercial_actions/new?prospect_id=${parseInt($(e.currentTarget).data().id)}`)
  }
}
