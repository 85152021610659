import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--aftersell-alert-messages-dashboard"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
  }

  clickTurboCell(e) {
    initModal()
    $('.modal-title').text(e.find('td').eq(0).text())
    $('.modal').modal('show')
    $('.modal-body').html(e.find('td').eq(2).html())
    if($(this.element).data().canHide) {
      $('.modal-footer').html(`
        <div class='d-flex justify-content-end' 
          data-controller="admin--aftersell-alert-messages-popup"
          data-message-id="${e.data().messageId}"
        >
          <p class='mb-0 btn new-btn btn-primary new-btn-primary'
            data-action='click->admin--aftersell-alert-messages-popup#hideMessage'
          >Cacher le message</p>
        </div>
      `)    
    } else {
      $('.modal-footer').hide()
    }

  }
}
