import IndexPages__GenericIndexPageController from "../generic_index_page_controller"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="index-pages--admin--new-customer-index-page"
export default class extends IndexPages__GenericIndexPageController {
  customConnect() {
    this.initSearch()
  }

  initSearch() {
    $("#index-page--new_customers__search").autocomplete({
      minLength: 3,
      delay: 300,
      source: ( request, response ) => {
        if(request.term.trim().length == 0) { return false; }
        let term = new URLSearchParams({term: request.term.toLowerCase()}).toString()
        $.get(`/admin/new_customers/live_autocomplete?${term}`).done(data => {
          response(data.new_customers.filter((new_customer, index) => {
            return true
          }).slice(0, 15))
        })
      },
      select: ( event, ui ) => {
        this.enterReloaded = true
        ajaxPostTurboStream($(this.element).data().endpoint, {id: ui.item.id})
        return false;
      }
    })
  }

  searchKeyUp(e) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.enterReloaded = true
        this.searchFilter()
      }
    }, 500);
  }

  searchBlur() {
    if(this.enterReloaded) {
      this.enterReloaded = false
    } else {
      this.searchFilter()
    }
  }

  searchFilter() {
    if($('#index-page--new_customers__search').val().length == 0) {
      this.filter()
    } else {
      ajaxPostTurboStream($(this.element).data().endpoint, {term: $('#index-page--new_customers__search').val()})
    }
  }
}
