import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--toporder-employee-whatsapp-list"
export default class extends Controller {
  selectAll() {
    $.each($(this.element).find('input'), (i, input) => {
      $(input).prop('checked', true)
    })
    this.checkToggled()
  }

  deselectAll() {
    $.each($(this.element).find('input'), (i, input) => {
      $(input).prop('checked', false)
    })    
    this.checkToggled()
  }

  checkToggled() {
    $('#toporder-employee-whatsapp-list').toggleClass('d-none', $(this.element).find('input:checked').length == 0)
  }

  apply() {
    ajaxPostTurboStream(
      '/admin/toporder_employee_whatsapp_batches',
      {
        toporder_employee_id: $(this.element).data().toporderEmployeeId,
        action_type: $(this.element).data().actionType,
        whats_app_groups: $(this.element).find('input:checked').map((i, e) => `${$(e).data().whatsAppGroupId}/${$(e).data().shopWhatsAppGroupId}/${$(e).data().name}`).get()
      },
      () => {
        Turbo.visit('/admin/toporder_employees')
      }
    )
  }
}
