import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
import { flashMessage } from 'shared/notify';
// Connects to data-controller="admin--commercial-actions-update-batch-date"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
    setTimeout(() => {
      $('#commercial_action_date').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
        var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
        $(".datepicker").css("top", top);
      });
    }, 500)
  }

  submitForm() {
    let body = $(this.element).data().filters
    body.change_date = $(this.element).find('#commercial_action_date').val()
    ajaxPostTurboStream(
      '/admin/commercial_actions/update_batch_dates',
      body,
      () => {
        $('#modal .modal').modal('hide')
        //$('#index-page--commercial_actions').get(0).controller.filter()
        flashMessage('', `Les modifications ont été appliquées`, 'success')
      }
    )
  }
}
