import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="admin--franchisor-nav"
export default class extends Controller {
  connect() {
    this.franchisorId = $(this.element).data().id
  }
  clickTab(e) {
    if($($(e.currentTarget).attr('href')).find('div').length == 0) {
      this.loadTab($(e.currentTarget).data().type, $(e.currentTarget).attr('href'), $(e.currentTarget).data().shopGroupId)
    }
  }

  loadTab(type, tab, shop_group_id) {
    if(type == 'devices') {
      ajaxTurboStream(
        `/admin/shop_devices/load_tab?target=${tab.replace('#', '')}&shop_group_id=${shop_group_id}`,
        false
      )
    } else {
      $('#loading_app').addClass('d-flex')
      $.ajax({
        dataType: 'script',
        url: `/admin/franchisor_tab/${this.franchisorId}?type=${type}&tab=${tab.replace('#', '')}`
      })      
    }

  }
}
