import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--toporder-connector-android"
export default class extends Controller {
  sendPush(e) {
    ajaxPostTurboStream(
      '/admin/toporder_connector_android/send_multiple_push',
      {deviceShops: $(this.element).data().deviceShops, code: $(e.currentTarget).data().code},
      () => {
        $(this.element).parents('.modal-body').html("<p class='mb-0'>La notification a bien été envoyée.</p>")
      }
    )
  }
}
