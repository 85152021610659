import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
import { flashMessage } from 'shared/notify'
// Connects to data-controller="admin--aftersell-alert-messages-popup"
export default class extends Controller {
  hideMessage() {
    ajaxPostTurboStream(
      '/admin/dashboard/hide_aftersell_alert_message',
      {messageId: $(this.element).data().messageId},
      () => {
        $('#modal_rails_5').modal('hide')
        flashMessage('', `Le message a bien été caché`, 'success')
        $(`#dashboard__load_aftersell_alert_messages__tr--${$(this.element).data().messageId}`).remove()
      }
    )
    
  }
}
