import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
import { flashMessage } from 'shared/notify';
// Connects to data-controller="admin--commercial-actions-batch-transfer-prospects"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
  }

  submitForm() {
    let body = $(this.element).data().filters
    body.change_toporder_employee_id = $(this.element).find('#commercial_action_toporder_employee_id').val()
    ajaxPostTurboStream(
      '/admin/commercial_actions/batch_transfer_prospects',
      body,
      () => {
        $('#modal .modal').modal('hide')
        $('#index-page--commercial_actions').get(0).controller.filter()
        flashMessage('', `Les modifications ont été appliquées`, 'success')
      }
    )
  }
}
